import {
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react';
import { type ChangeEvent, type KeyboardEvent, useRef, useState } from 'react';

interface Input<T> {
  onSearch: (value: string) => Promise<T[]>;
  onSelectValue: (value: T | null) => void;
}

export function useFloatingForAutocomplete<T>({ onSearch, onSelectValue }: Input<T>) {
  const [searchResults, setSearchResults] = useState<readonly T[]>([]);

  async function performSearch(event: ChangeEvent<HTMLInputElement>) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      return;
    }

    const results = await onSearch(value);
    if (results.length > 0) {
      setSearchResults(results);
    }
    setIsOpen(results.length > 0);
    setActiveIndex(0);
  }

  function onKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && activeIndex !== null) {
      // eslint-disable-next-line security/detect-object-injection
      onSelectValue(searchResults[activeIndex] ?? null);
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    transform: false,
    middleware: [
      offset(10),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 8,
      }),
    ],
  });
  const dismiss = useDismiss(context, { outsidePressEvent: 'click' });
  const listRef = useRef<(HTMLDivElement | null)[]>([]);
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([listNavigation, dismiss]);

  function openSuggestions() {
    setIsOpen(searchResults.length > 0);
  }

  return {
    isOpen,
    refs,
    floatingStyles,
    getReferenceProps,
    inputProps: {
      onChange: performSearch,
      onKeyUp,
      onFocus: openSuggestions,
    },
    getFloatingProps,
    activeIndex,
    searchResults,
    listRef,
  };
}
