import { useRef, useState } from 'react';

import { ensureError } from '../../common/utils';
import { type SearchedSchool, autocompleteByDomain, autocompleteByName } from '../autocomplete';

interface InitialState {
  error?: string | null;
}

type IdentityProvider = SearchedSchool['identityProviders'][number];

export function useSearchableSchool({ error: initialError }: InitialState) {
  const abortController = useRef(new AbortController());

  const [error, setError] = useState<string | null>(initialError ?? null);

  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [selectedIdentityProvider, setSelectedIdentityProvider] = useState<
    IdentityProvider['id'] | null
  >(null);

  async function searchByMail(mail: string): Promise<
    (Omit<SearchedSchool, 'identityProviders'> & {
      identityProviders: (SearchedSchool['identityProviders'][number] & { email: string })[];
    })[]
  > {
    // Cancel previous requests
    abortController.current.abort();

    // use new abort controller for new request
    abortController.current = new AbortController();
    const [username, domain] = mail.split('@');
    if (!domain) {
      return [];
    }

    try {
      const data = await autocompleteByDomain(domain, { signal: abortController.current.signal });
      return data.map((school) => {
        return {
          ...school,
          identityProviders: school.identityProviders
            .filter((idp) => !!idp.mailDomain)
            .map((idp) => ({ ...idp, email: `${username}@${idp.mailDomain}` })),
        };
      });
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        // ignore abortions
        return [];
      }
      setError(ensureError(error).message);
      return [];
    }
  }

  async function searchByName(name: string): Promise<SearchedSchool[]> {
    // Cancel previous requests
    abortController.current.abort();

    // use new abort controller for new request
    abortController.current = new AbortController();

    try {
      const data = await autocompleteByName(name, { signal: abortController.current.signal });
      return data;
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        // ignore abortions
        return [];
      }
      setError(ensureError(error).message);
      return [];
    }
  }

  return {
    error,
    searchByMail,
    searchByName,
    selectedEmail,
    selectedIdentityProvider,
    setSelectedEmail,
    setSelectedIdentityProvider,
  };
}
