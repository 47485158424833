import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons/faArrowLeftLong';
import clsx from 'clsx';
import { useRef } from 'react';

import { AutocompleteInput } from '../common/AutocompleteInput/AutocompleteInput';
import { Divider } from '../common/Divider';
import { EPButton } from '../common/EPButton';
import { Subtitle, Title } from '../common/Headlines';
import { InputGroup } from '../common/InputGroup';
import { Label } from '../common/Label';
import { type TranslationKey, useTranslations } from '../intl/useTranslations';
import { getLoginChallenge } from '../store/challenges';
import { getServerSideErrors } from '../store/error';
import type { RememberedSchool } from '../store/schools';
import { useSearchableSchool } from './hooks/useSearchableSchool';

interface Props {
  hasRememberedSchools: boolean;
  onSelectOverview: () => void;
  onAddRememberedSchool: (school: RememberedSchool) => void;
  className?: string;
}

interface AutocompleteIdentityProvider {
  id: string;
  mailDomain: string | null;
  label: string;
  group: {
    id: string;
    name: string;
    address: {
      zipCode: string;
      city: string;
    } | null;
  };
}

export function SearchSchool({
  hasRememberedSchools,
  onSelectOverview,
  onAddRememberedSchool,
  className,
}: Props) {
  const t = useTranslations('login');

  const {
    error,
    selectedEmail,
    selectedIdentityProvider,
    searchByMail,
    searchByName,
    setSelectedEmail,
    setSelectedIdentityProvider,
  } = useSearchableSchool({
    error: getServerSideErrors() ? t(getServerSideErrors() as TranslationKey<'login'>) : null,
  });
  const formRef = useRef<HTMLFormElement>(null);

  function selectEmail(
    identityProvider: (AutocompleteIdentityProvider & { email: string }) | null,
  ): void {
    setSelectedEmail(identityProvider?.email ?? null);
    rememberSchool(identityProvider);
    // Wait for react to propagate state change
    setTimeout(() => formRef.current?.submit(), 0);
  }

  function selectSchool(identityProvider: AutocompleteIdentityProvider | null): void {
    setSelectedIdentityProvider(identityProvider?.id ?? null);
    rememberSchool(identityProvider);
    // Wait for react to propagate state change
    setTimeout(() => formRef.current?.submit(), 0);
  }

  function rememberSchool(identityProvider: AutocompleteIdentityProvider | null) {
    if (!identityProvider) {
      return;
    }
    onAddRememberedSchool({
      identityProviderId: identityProvider.id,
      subtitle: identityProvider.label,
      name: identityProvider.group.name,
    });
  }

  async function searchByNameForAutocomplete(
    name: string,
  ): Promise<AutocompleteIdentityProvider[]> {
    const rawResult = await searchByName(name);

    return rawResult.flatMap((result) => {
      return result.identityProviders.map((idp) => ({
        group: { id: result.id, name: result.name, address: result.address },
        ...idp,
      }));
    });
  }

  async function searchByMailForAutocomplete(
    mail: string,
  ): Promise<(AutocompleteIdentityProvider & { email: string })[]> {
    const rawResult = await searchByMail(mail);

    return rawResult.flatMap((result) => {
      return result.identityProviders.map((idp) => ({
        group: { id: result.id, name: result.name, address: result.address },
        ...idp,
      }));
    });
  }

  return (
    <div className={clsx(className, 'flex flex-col justify-between gap-10')}>
      <div className="flex flex-col gap-2">
        <Title>{t('search.title')}</Title>
        <Subtitle>{t('search.subtitle')}</Subtitle>
      </div>
      {error && <div className="w-0 min-w-full text-epmars">{error}</div>}
      <div>
        <InputGroup>
          <Label forId="input-school" className="text-white md:text-epspacedust">
            {t('search.school')}
          </Label>
          <AutocompleteInput<AutocompleteIdentityProvider['group'], AutocompleteIdentityProvider>
            id="input-school"
            search={searchByNameForAutocomplete}
            onSelectValue={selectSchool}
            groupLabel={(group) => group.name}
            groupSubLabel={(group) => {
              return group.address ? `${group.address.zipCode} ${group.address.city}` : null;
            }}
            label={(result) => {
              return result.label;
            }}
          />
        </InputGroup>
        <Divider>{t('search.or')}</Divider>
        <InputGroup>
          <Label forId="input-email" className="text-white md:text-epspacedust">
            {t('search.email')}
          </Label>
          <AutocompleteInput<
            AutocompleteIdentityProvider['group'],
            AutocompleteIdentityProvider & { email: string }
          >
            id="input-email"
            search={searchByMailForAutocomplete}
            onSelectValue={selectEmail}
            groupLabel={(group) => group.name}
            groupSubLabel={(group) => {
              return group.address ? `${group.address.zipCode} ${group.address.city}` : null;
            }}
            label={(result) => result.email}
          />
        </InputGroup>
      </div>
      <form
        className="flex items-center justify-stretch gap-2"
        action="/login/confirm"
        method="post"
        ref={formRef}
      >
        {hasRememberedSchools && (
          <>
            <EPButton
              className="grow items-center justify-center max-md:hidden"
              mode="ghost"
              onClick={onSelectOverview}
              iconBefore={faArrowLeftLong}
            >
              {t('back')}
            </EPButton>
            <EPButton
              className="grow items-center justify-center md:hidden"
              textColorClassName="text-white hover:text-epinfrapurple"
              mode="ghost"
              onClick={onSelectOverview}
              iconBefore={faArrowLeftLong}
            >
              {t('back')}
            </EPButton>
          </>
        )}
        {selectedEmail && <input type="hidden" name="mail" value={selectedEmail} />}
        {selectedIdentityProvider && (
          <input type="hidden" name="school" value={selectedIdentityProvider} />
        )}
        <input type="hidden" name="login_challenge" value={getLoginChallenge()} />
      </form>
    </div>
  );
}
