import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import clsx from 'clsx';
import { createRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { EPButton } from '../common/EPButton';
import { Subtitle, Title } from '../common/Headlines';
import { groupBy } from '../common/utils';
import { useToggle } from '../hooks/useToggle';
import { type TranslationKey, useTranslations } from '../intl/useTranslations';
import { getLoginChallenge } from '../store/challenges';
import { getServerSideErrors } from '../store/error';
import type { RememberedSchool } from '../store/schools';
import { RememberedSchoolCard } from './RememberedSchoolCard';

interface Props {
  rememberedSchools: RememberedSchool[];
  onSelectMissingSchool: () => void;
  onRemoveRememberedSchool: (school: RememberedSchool) => void;
  className?: string;
}

export function RememberedSchools({
  rememberedSchools,
  onSelectMissingSchool,
  onRemoveRememberedSchool,
  className,
}: Props) {
  const t = useTranslations('login');
  const error = getServerSideErrors() ? t(getServerSideErrors() as TranslationKey<'login'>) : null;
  const { isActive: isEditing, toggle: toggleEditing } = useToggle(false);

  const schools = rememberedSchools.map((school) => ({
    school,
    nodeRef: createRef<HTMLFormElement>(),
  }));

  // only display subtitle if there are multiple schools with the same name
  const shouldDisplaySubtitle = Array.from(
    groupBy(rememberedSchools, (school) => school.name).values(),
  ).some((schools) => schools.length > 1);

  return (
    <div className={clsx(className, 'flex flex-col justify-between gap-10')}>
      <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
        <div className="flex flex-col gap-2">
          <Title>{t('select.title')}</Title>
          <div className="flex items-center justify-between">
            <Subtitle>{t('select.subtitle')}</Subtitle>
            <EPButton
              mode="ghost"
              iconAfter={faPencil}
              active={isEditing}
              onClick={toggleEditing}
              className="self-end text-white hover:text-epinfrapurple md:hidden"
            />
          </div>
        </div>
        <EPButton
          mode="ghost"
          iconAfter={isEditing ? faXmark : faPencil}
          active={isEditing}
          onClick={toggleEditing}
          activeBackgroundClassName={isEditing ? 'bg-epinfrapurple' : undefined}
          className="max-md:hidden"
        />
      </div>
      {error && <div className="w-0 min-w-full text-epmars">{error}</div>}
      <div className="flex flex-col gap-6">
        <TransitionGroup component={null}>
          {schools.map(({ school, nodeRef }) => (
            <CSSTransition
              key={school.identityProviderId}
              appear
              addEndListener={(done) => {
                nodeRef.current?.addEventListener('transitionend', done, false);
              }}
              classNames={{
                appearDone: 'grid-rows-[1fr]',
                exit: 'opacity-0 grid-rows-[0fr]',
              }}
              nodeRef={nodeRef}
            >
              <form
                className="grid transition-all duration-500 ease-epease"
                ref={nodeRef}
                action="/login/confirm"
                method="post"
                onSubmit={(e) => {
                  if (isEditing) {
                    e.preventDefault();
                    onRemoveRememberedSchool(school);
                  }
                }}
              >
                <div className="overflow-hidden">
                  <input type="hidden" name="login_challenge" value={getLoginChallenge()} />
                  <input type="hidden" name="school" value={school.identityProviderId} />
                  <button type="submit" className="w-full">
                    <RememberedSchoolCard
                      isEditing={isEditing}
                      school={school}
                      shouldDisplaySubtitle={shouldDisplaySubtitle}
                    />
                  </button>
                </div>
              </form>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>

      <EPButton
        mode="ghost"
        className="justify-center text-white hover:text-epinfrapurple md:text-epinfrapurple"
        onClick={onSelectMissingSchool}
      >
        {t('select.select')}
      </EPButton>
    </div>
  );
}
