import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { useRememberedSchools } from '../hooks/useRememberedSchools';
import { ContentBox } from '../layout/ContentBox';
import { Layout } from '../layout/Layout';
import { RememberedSchools } from './RememberedSchools';
import { SearchSchool } from './SearchSchool';

export function LoginPage() {
  const { rememberedSchools, addRememberedSchool, removeRememberedSchool } = useRememberedSchools();
  const [screen, setScreen] = useState<'remembered' | 'search'>(
    rememberedSchools.length > 0 ? 'remembered' : 'search',
  );

  // Fix for heylogin overlays visible off-screen
  const [shouldHideSearchSchool, setShouldHideSearchSchool] = useState(screen !== 'search');

  useEffect(() => {
    if (screen === 'remembered' && rememberedSchools.length === 0) {
      setScreen('search');
    }
  }, [rememberedSchools.length]);

  useEffect(() => {
    if (screen !== 'search') {
      const timeout = setTimeout(() => {
        setShouldHideSearchSchool(true);
      }, 500);
      return () => {
        setShouldHideSearchSchool(true);
        clearTimeout(timeout);
      };
    } else {
      setShouldHideSearchSchool(false);
    }
  }, [screen]);

  function selectSearchSchool() {
    setScreen('search');
  }
  function selectOverview() {
    setScreen('remembered');
  }

  /**
   * We are using the following trick to animate the transition between the two screens:
   *
   * ┌─────────────────┐
   * │container        │
   * │┌───────────────┐│┌──────────────┐
   * ││screen1        │││screen2       │
   * ││               │││              │
   * │└───────────────┘│└──────────────┘
   * └─────────────────┘
   *
   * The container is a flexbox
   * The screens use basis-full (or w-full) and shrink-0.
   * That means that each screen use the full width of the container.
   * The container is as wide as both screens combined.
   * To fix that, we set the width of the container.
   *
   * Transitioning between screens is achived by using transform(-100%-padding)
   */

  return (
    <Layout className="flex items-center justify-center">
      <ContentBox className="w-0 overflow-hidden max-md:mt-20">
        <div
          className={clsx(
            screen === 'search' && '-translate-x-[calc(100%+2.5rem)]', // 100% + gap-10
            'flex justify-start gap-10 transition-transform duration-500 ease-epease', // gap-10===p-10 from ContentBox
          )}
        >
          <RememberedSchools
            // w-full uses the full width of the parent element
            // shink-0 enforces that only the first element fits into the parent element
            className="w-full shrink-0 transition-all duration-500 ease-epease"
            onSelectMissingSchool={selectSearchSchool}
            rememberedSchools={rememberedSchools}
            onRemoveRememberedSchool={removeRememberedSchool}
          />
          <SearchSchool
            className={clsx(
              shouldHideSearchSchool && 'hidden',
              'w-full shrink-0 transition-all duration-500 ease-epease',
            )}
            hasRememberedSchools={rememberedSchools.length > 0}
            onSelectOverview={selectOverview}
            onAddRememberedSchool={(school) => addRememberedSchool(school, false)}
          />
        </div>
      </ContentBox>
    </Layout>
  );
}
